<template>
  <main class="RoutEasySync">
    <TheSubHeader
      icon="rote"
      title="RoutEasy"
      page-title-tag="RoutEasy Sync"
      type="filter"
      :actions="['back']"
      organizer-gap="1rem"
      organizer-columns="340px 1fr 100px 1fr 160px"
    >
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Período"
          type="date"
          @input="resetSearch()"
        />
        <!-- Store -->
        <BaseTreeSelect
          ref="lojista"
          v-model="filters.store_id"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione um Lojista"
          :select-options="null"
          :fetch-options="'stores/fetchRoutEasyStoresAsync'"
          @input="filterStores"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.status"
          class="RoutEasySync__status"
          name="Status"
          label="Status"
          placeholder="Status"
          :clearable="false"
          :select-options="filterStatusOptions"
        />
        <!-- Orders -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.query"
          name="Pesquisar"
          label="Pesquisar"
          placeholder="Pesquisar"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :enable-bipping="true"
          :tooltip="tooltip"
          @configBiping="configBiping"
          @bipping="itsBipping = true, onInputBarcodeScanned($event)"
          @not-bipping="onCodeDefault"
          @select="callbackInputBarcodeScanned"
          @deselect="callbackInputBarcodeScanned"
          @clear="callbackInputBarcodeScanned"
        />
        <!-- Filter -->
        <BaseButton
          label="Filtrar"
          class="RoutEasySync__btn"
          theme="icon-left"
          color="primary-base"
          filled
          icon="filter"
          text-color="white"
          :disabled="!isSearchable"
          @click="resetSearch()"
        />
      </template>

      <template v-if="allTags.length" #tag>
        <FiltersTag v-if="allTags.length" :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>

    <section class="RoutEasySync__orders">
      <div class="RoutEasySync__ordersContent"> 
        <div v-if="firstSearch && isSearchable" class="RoutEasySync__table">
          <DataTable
            v-model="form.order_ids"
            class="RoutEasySync__table--dataTable"
            :header="ordersWithoutRoutesFilteredHeader"
            :table="ordersWithoutRoutes.data.content"
          />

          <div class="RoutEasySync__table--confirm">
            <div class="RoutEasySync__table--information">          
              <p>{{ ordersSelectedText }}</p>
            </div>

            <DynamicForm
              ref="form"
              class="RoutEasySync__form"
              :model="form"
              :blueprint="blueprint({save, todayParsed})"
              :endpoint-errors="endpointErrors"
              submit-type="json"
              @filterDrivers="search()"
              @submit="onSubmit"
            />
          </div>

          <Pagination class="RoutEasySync__table--pagination" @page="search()" @paginate="search()" />
        </div>

        <div v-else class="RoutEasySync__firstSearch">
          <p>
            Utilize os filtros para exibir os pedidos.<br>
            Selecione ao menos um intervalo de datas e uma loja.
          </p>
        </div>        
      </div>
    </section>

    <Dialog
      v-if="showDialog"
      class="RoutEasySync__dialog"
      :warning="dialogText"
      :label-cancel="false"
      label-confirm="Confirmar"
      @confirm="confirmDialog"
    >
      <BaseList v-if="missingOrders.length > 0" :list="missingOrders" :title="'Nome do Pedido'" :scrollbar="true" scrollbar-height="25vh" />

      <DataTable
        v-if="ordersErrors.content.length"
        :header="ordersErrors.headers"
        :table="tableContentWithId"
      />
    </Dialog>

    <BarcodeScannerSettings
      ref="barcodeScannerSettings"
      v-bind="currentProperties"
      :stores="storesToBarcodeSettings"
      @save="handlerSaveBarcodeScannerSetting"
      @restore="handlerRestoreBarcodeScannerSetting"
      @delete="handlerDeleteBarcodeScannerSetting"
    />
  </main>
</template>

<script>
import { routes } from '@/api'
import { mapActions, mapState, mapGetters } from 'vuex'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import { DynamicForm, Pagination, DataTable, FiltersTag, Dialog, BarcodeScannerSettings, BaseList } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './RoutEasySync.blueprint'
import { BarCodeScanner } from '@/plugins/barCodeScanner/index'
import barCodeScannerMixin from '@/mixins/barCodeScanner'
import beepingSoundMixin from '@/mixins/beepingSound'

export default {
  name: 'RoutEasySync',
  components: {
    BaseList,
    DataTable,
    BaseDatePicker,
    BaseTreeSelect,
    BaseButton,
    TheSubHeader,
    DynamicForm,
    Pagination,
    FiltersTag,
    Dialog,
    BarcodeScannerSettings,
  },
  mixins: [barCodeScannerMixin, beepingSoundMixin],
  data() {
    return {
      allTags: [],
      form: {
        order_ids: [],
      },
      filters: {
        query: [],
        store_id: null,
        dateRange: {
          init: null,
          end: null,
        },
        status: {id: 0, name: 'Todos' }
      },
      
      filterStatusOptions: [
        {id: 0, name: 'Todos'},
        {id: 1, name: 'Pendente' },
        {id: 2, name: 'Standby'}
      ],
      endpointErrors: {},
      showDialog: false,
      dialogText: '',
      missingOrders: [],
      ordersErrors: {
        headers: {},
        content: []
      },
      firstSearch: false
    }
  },

  computed: {
    ...mapState({
      ordersWithoutRoutes: state => state.routes.ordersWithoutRoutes,
      routesNameByStore: state => state.routes.routesNameByStore,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      storesImport: state => state.stores.storesImport,
    }),

    ...mapGetters({
      transitPointImport: 'routes/transitPointImportWithoutParentStores'
    }),

    isSearchable() {
      return !!(this.filters.dateRange.init && this.filters.dateRange.end && this.filters.store_id)
    },

    parsedFormStoreId() {
      if (this.filters.store_id && !this.filters.store_id.schedule) {
        let stores = []
        stores.push({
          id: this.filters.store_id.id
        })
        return stores
      } else if (this.filters.store_id && this.filters.store_id.schedule) {
        return this.filters.store_id.stores.map(m => {
          return {
            id: m.id
          }
        })
      } else {
        return []
      }
    },

    dateRangeInit() {
      const days = 15
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },

    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },

    save(){
      return !(this.form.order_ids.length > 0 && this.filters.dateRange.init && this.filters.dateRange.end)
    },

    todayParsed() {
      let today = new Date()
      return Date.parse(today)
    },

    ordersWithoutRoutesFilteredHeader() {
      let o = this.ordersWithoutRoutes.data.headers
      if (o.created_at && o.created_at.method === 'millisecondsToDate') {
        o.created_at.method = 'millisecondsToDateHour'
      }
      return o
    },

    ordersSelectedText() {
      let text
      let total = this.form.order_ids.length
      if (total > 0) {
        text = `${total} pedido${total === 1 ? '' : 's'} selecionado${total === 1 ? '' : 's'}`
      } else {
        text = `Marque nas caixas de seleção ${window.innerWidth < 960 ? 'abaixo' : 'acima'} os pedidos para sincronizar no RoutEasy.`
      }
      return text
    },

    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    parsedStatus() {
      const status = []
      const id = this.filters.status ? this.filters.status.id : null
      switch (id) {
      case 1:
        status.push({id: '__const:storeStatusType__pendente', name: 'Pendente' })
        break
      case 2:
        status.push({id: '__const:storeStatusType__standby', name: 'Standby'})
        break
      default:
        status.push({id: '__const:storeStatusType__pendente', name: 'Pendente' })
        status.push({id: '__const:storeStatusType__standby', name: 'Standby'})
      }
      return status
    },

    tableContentWithId() {
      const table = this.ordersErrors.content.map((el, index) => {
        let id = Math.floor(Date.now() / 1000) + index
        return {...el, id} 
      })

      return table
    }
  },

  async mounted() {
    this.setInitialDate()
  },

  methods: {
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
      fetchOrdersWithoutRoutes: 'routes/fetchOrdersWithoutRoutes',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination'
    }),

    //[INFO] - Barcode Settings Methods are insade mixin (barCodeScannerMixin)
    ...BarCodeScanner({
      onCodeDefault: 'onCodeDefault',
      onCodeSliced: 'onCodeSliced',
      onCodeBipping: 'onCodeBipping',
      callbackBarcodeScanned: 'callbackBarcodeScanned',
      callbackInputBarcodeScanned: 'callbackInputBarcodeScanned',
    }),

    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },

    async filterStores(data){
      await this.filterBarcodeSettings(data, this.callbackBarcodeScanned)
      this.resetSearch()
    },

    updateTags(){
      this.allTags = [...this.filters.query]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id.toLowerCase() !== id.toLowerCase())
      this.filters.query = this.filters.query.filter((tag) => tag.name.toLowerCase() !== name.toLowerCase())
      this.resetSearch() 
    },

    clearAllTags(){
      this.allTags = []
      this.filters.query = []
      this.resetSearch() 
    },

    checkIfOrdersExist() {
      let query = [...new Set(this.filters.query.map(q => q.name))]
      let ordersNumber = this.ordersWithoutRoutes.data.content.map(order => order.order_number != null ? order.order_number.toLowerCase() : order.order_number)
      let ordersInvoiceNumber = this.ordersWithoutRoutes.data.content.map(order => order.invoice_number != null ? order.invoice_number.toLowerCase() : order.invoice_number)

      this.missingOrders = query.filter(f => !ordersNumber.includes(f.toLowerCase()) && !ordersInvoiceNumber.includes(f.toLowerCase()))
      if (this.missingOrders.length > 0) {
        if (this.missingOrders.length > 1) {
          this.dialogText = 'Alguns pedidos de sua pesquisa não foram encontrados no sistema com base nos filtros que você escolheu ou não estão com o status apto para serem roteirizados:'
        } else {
          this.dialogText = 'Um pedido da sua pesquisa não foi encontrado no sistema com base nos filtros que você escolheu ou não está com o status apto para ser roteirizado:'
        }
        this.showDialog = true
        if (!this.firstSearch && this.hasValueOnInput) this.barcodeBeepingCheck(false)
      } else {
        if(!this.firstSearch && this.hasValueOnInput) this.barcodeBeepingCheck(true)
      }
    },

    confirmDialog() {
      if (this.missingOrders.length > 0) {
        this.removeMissingOrders()
      } else {
        this.cleanFilters()
      }
      this.showDialog = false
    },

    cleanFilters() {
      this.ordersErrors.headers = {}
      this.ordersErrors.content = []

      this.filters.query = []
      this.updateTags()
      this.resetSearch()
    },

    removeMissingOrders() {
      let m = this.missingOrders
      for (let i = 0; i < m.length; i++) {
        this.removeTag(m[i], m[i])
        this.$refs.query.removeTag(m[i])
      }
      this.showDialog = false
    },

    resetSearch() {
      if (this.filters.dateRange.init && this.filters.dateRange.end && this.filters.store_id) {
        this.form.order_ids = []
        this.clearPagination(this.filters.query.length > 50 ? this.filters.query.length : 50).then(() => this.search())
      }
    },

    async search() {
      const params = {
        obj: {
          date_hour_start: this.filters.dateRange.init,
          date_hour_end: this.filters.dateRange.end + (24 * 60 * 60 * 1000) - 1,
          query: this.filters.query,
          'filter[status][store_status_type_id]': this.parsedStatus,
          'store_id': this.parsedFormStoreId
        },
        paginate: this.paginate,
        page: this.page
      }
      
      await this.fetchOrdersWithoutRoutes(params)
        .then(() => {
          let ordersReturned = this.ordersWithoutRoutes.data.content.map(order => order.id)
          this.form.order_ids = this.form.order_ids.filter(order => ordersReturned.includes(order.id))
          this.checkIfOrdersExist()
          this.setPagination(this.ordersWithoutRoutes)
          this.firstSearch = true
        })

      this.setLoading(false)
    
    },

    async onSubmit() {
      this.setLoading(true)
      let body = {
        'orderNumbers': this.form.order_ids.map(o => o.order_number),
        'shouldForceResync': true
      }
      await routes.routEasySync(body, () => {
        this.dialogText = 'Pedido(s) enviado(s) com sucesso!'
        this.showDialog = true
        this.search()
      }, (e) => {
        this.dialogText = 'Alguns pedidos de sua pesquisa não puderam ser enviados:'
        this.ordersErrors.headers = e.data.headers
        this.ordersErrors.content = e.data.content
        this.showDialog = true
        this.search()
      }, () => this.setLoading(false))
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.RoutEasySync {
  max-width: 100vw;

  /deep/.Organizer__filter{
    align-items: flex-end;
  }

  &__content,
  &__orders {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". main .";
    grid-column-gap: 1rem;
    margin-bottom: 40px;
  }

  &__firstSearch {
    grid-area: table;
    margin-bottom: 50px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      margin: 0;
      padding: 0;
      text-align: center;
      background: $gradient-primary;
    }
  }

  &__form,
  &__ordersContent {
    grid-area: main;
  }

  &__form {
    width: 100%;
    grid-row-gap: 1rem;
    grid-template-areas: 

      '. . . . . . . . . . confirm confirm ';
    align-items: flex-end;
    @media #{$mobile-view} {
      width: 100%;
    }
  }

  &__dialog {

    .Dialog__Texts {
      width: 100%;
      margin-top: 0;
      height: auto;
      max-height: 60vh;
      overflow-y: auto;

      .Dialog__Warning {
        padding: 10px 20px;
      }
    }
  }

  &__ordersContent {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-areas:
      "filter"
      "table";
    @media #{$mobile-view} {
      grid-template-columns: 1fr;
      grid-template-areas:
        "filter"
        "table";
    }
  }
  &__filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem 1rem;
    align-items: flex-end;
  }
  @media #{$mobile-view} {
    &__dateRange,
    &__status,
    &__btn { width: 100%; max-width: initial; }
  }

  &__table {
    grid-area: table;
    display: flex;
    flex-direction: column;

    &--dataTable {
      @media #{$mobile-view} {
        order: 1;
        position: relative;
      }
    }

    &--pagination {
      @media #{$mobile-view} {
        order: 2;
        position: relative;
      }
    }

    &--confirm {
      position: sticky;
      bottom: 0;
      padding: 15px 0 30px;
      background: white;
      z-index: $z-active;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media #{$mobile-view} {
        padding: 0;
        margin-top: 0;
        margin-bottom: 20px;
        order: 0;
        position: relative;
      }

      p {
        font-size: $font-size-3xsmall;
        margin: 0 0 15px 0;
        padding: 0;
      }
      &:before {
        content: '';
        width: 300vw;
        display: block;
        position: absolute;
        height: 50px;
        bottom: 100%;
        left: -100vw;
        @media #{$mobile-view} {
          display: none;
        }
        pointer-events: none;
        background: linear-gradient(
          to top,
          rgba($color-neutral-stronger, 0.1) 0%,
          rgba($color-neutral-stronger, 0) 50%
        );
      }
      @media #{$mobile-view} {
        flex-direction: column;
        width: 100%;
          z-index: 4001
      }
    }

    &--information {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>