export default ({ save }) => {

  return [ 
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Confirmar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        filled: true,
        disabled: save
      }
    }
  ]
}